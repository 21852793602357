import dynamic from 'next/dynamic';
import HorizontalSpacerTastic from '@frontastic/catwalk/src/js/tastic/horisontal-spacer/tastic';
import BoostContentMarkdownTastic from '@frontastic/theme-boost/src/js/tastic/content/markdown/tastic';
import BoostContentShowcaseTextTastic from '@frontastic/theme-boost/src/js/tastic/content/showcase-text/tastic';
import BoostContentSimpleTileTastic from '@frontastic/theme-boost/src/js/tastic/content/simple-tile/tastic';
import BoostContentTileTastic from '@frontastic/theme-boost/src/js/tastic/content/tile/tastic';
import BoostContentTileV2Tastic from '@frontastic/theme-boost/src/js/tastic/content/tile-v2/tastic';
import BoostHorizontalSpacerTastic from '@frontastic/theme-boost/src/js/tastic/helpers/horizontal-spacer/tastic';
import MarkdownPanelTastic from '@frontastic/theme-boost/src/js/tastic/product/product-details/markdown-panel/tastic';
import BoostProductListingPageTastic from '@frontastic/theme-boost/src/js/tastic/product-listing/product-listing-page/tastic';
import { BlogDetail as BlogDetailTastic } from '../../legacy/js/tastic/graphcms/blog-detail/tastic';

export const tastics = {
  'frontastic/boost/content/simple-tile': BoostContentSimpleTileTastic,
  'frontastic/boost/content/markdown': BoostContentMarkdownTastic,
  'frontastic/boost/content/showcase-text': BoostContentShowcaseTextTastic,
  'frontastic/boost/content/tile': BoostContentTileTastic,
  'frontastic/boost/content/tile-v2': BoostContentTileV2Tastic,
  'frontastic/boost/helpers/horizontal-spacer': BoostHorizontalSpacerTastic,
  'frontastic/boost/product-listing/product-listing-page': BoostProductListingPageTastic,
  'horizontal-spacer': HorizontalSpacerTastic,
  'sm-general/horizontal-spacer': HorizontalSpacerTastic,
  'frontastic/boost/horizontal-spacer': HorizontalSpacerTastic,
  'frontastic/boost/product-details/markdown-panel': MarkdownPanelTastic,
  'anwr/blogDetail': BlogDetailTastic,
  'sm-general/banner-tiles': dynamic(() => import('../../legacy/js/tastic/bannertiles/tastic')),
  'slider/brand-slider': dynamic(() => import('../../legacy/js/tastic/sliders/brand-slider/tastic')),
  'sm-general/image-container': dynamic(() => import('../../legacy/js/tastic/imagecontainer/tastic')),
  'sm-general/image-gallery': dynamic(() => import('../../legacy/js/tastic/imageGallery/tastic')),
  'sm-general/markdown': dynamic(() => import('../../legacy/js/tastic/markdown/tastic')),
  'sm-general/singleButton': dynamic(() => import('../../legacy/js/tastic/singleButton/tastic')),
  'sm-general/teaser': dynamic(() => import('../../legacy/js/tastic/teaser/tastic')),
  'sm-slider/small-tile-slider': dynamic(() => import('../../legacy/js/tastic/sliders/small-tile-slider/tastic')),
  'advantage-bar': dynamic(() => import('../../legacy/js/tastic/advantage/tastic')),
  'frontastic/boost/account/access': dynamic(() => import('../../legacy/js/tastic/account/access/tastic')),
  'frontastic/boost/account/reset-password': dynamic(
    () => import('../../legacy/js/tastic/account/resetPassword/tastic'),
  ),
  'anwr/graphcms/blogCategoryList': dynamic(() => import('../../legacy/js/tastic/graphcms/blog-category-list/tastic')),
  'anwr/graphcms/blogList': dynamic(() => import('../../legacy/js/tastic/graphcms/blog-list/tastic')),
  'anwr/graphcms/brandContentFull': dynamic(
    () => import('../../legacy/js/tastic/graphcms/brand-content-full-desc/tastic'),
  ),
  'anwr/graphcms/brandContentShort': dynamic(
    () => import('../../legacy/js/tastic/graphcms/brand-content-short-desc/tastic'),
  ),
  'anwr/graphcms/brandSizeTable': dynamic(() => import('../../legacy/js/tastic/graphcms/brand-size-table/tastic')),
  'anwr/graphcms/productCategoryContent': dynamic(
    () => import('../../legacy/js/tastic/graphcms/product-category-content/tastic'),
  ),
  'frontastic/boost/account/confirm': dynamic(() => import('../../legacy/js/tastic/account/confirm/tastic')),
  'frontastic/boost/style-guide': dynamic(() => import('../../legacy/js/tastic/styleguide/tastic')),
  'frontastic/boost/account/addresses': dynamic(() => import('../../legacy/js/tastic/account/addresses/tastic')),
  'frontastic/boost/account/orders': dynamic(() => import('../../legacy/js/tastic/account/orders/tastic')),
  'frontastic/boost/account/profile': dynamic(() => import('../../legacy/js/tastic/account/profile/tastic')),
  'frontastic/boost/checkout-sm': dynamic(() => import('../../legacy/js/tastic/checkout/tastic')),
  'sm-general/branchMapEurope': dynamic(() => import('../../legacy/js/tastic/branchMapEurope/tastic')),
  'sm-general/branchMapIndividualMap': dynamic(() => import('../../legacy/js/tastic/branchMapIndividualMap/tastic')),
  'sm-algolia/product-list': dynamic(() => import('../../legacy/js/tastic/algolia/productList/tastic')),
  'sm-algolia/product-recommendation-list': dynamic(
    () => import('../../legacy/js/tastic/algolia/productRecommendationList/tastic'),
  ),
  'sm-algolia/product-tiles': dynamic(() => import('../../legacy/js/tastic/algolia/productTiles/tastic')),
  'sm-algolia/store-finder-algolia': dynamic(() => import('../../legacy/js/tastic/algolia/storeFinder/tastic')),
  'sm-algolia/trending-product-tiles': dynamic(
    () => import('../../legacy/js/tastic/algolia/trendingProductTiles/tastic'),
  ),
  'sm-algolia/store-detail': dynamic(() => import('../../legacy/js/tastic/algolia/storeDetail/tastic')),
  'sm-general/ProductTiles': dynamic(() => import('../../legacy/js/tastic/productTiles/tastic')),
  'sm-general/SimpleTile': dynamic(() => import('../../legacy/js/tastic/simpleTile/tastic')),
  'sm-general/accordions': dynamic(() => import('../../legacy/js/tastic/accordions/tastic')),
  'sm-general/advent-calendar': dynamic(() => import('../../legacy/js/tastic/advent-calendar/tastic')),
  'sm-general/banners': dynamic(() => import('../../legacy/js/tastic/banners/tastic')),
  'sm-general/branchMap': dynamic(() => import('../../legacy/js/tastic/branchMap/tastic')),
  'sm-general/brandList': dynamic(() => import('../../legacy/js/tastic/brandList/tastic')),
  'sm-general/breadCrumb': dynamic(() => import('../../legacy/js/tastic/breadcrumb/tastic')),
  'sm-general/cart': dynamic(() => import('../../legacy/js/tastic/cart/tastic')),
  'sm-general/contact-form': dynamic(() => import('../../legacy/js/tastic/contactForm/tastic')),
  'sm-general/forms/mailjetNewsletter': dynamic(() => import('../../legacy/js/tastic/forms/mailjetNewsletter/tastic')),
  'sm-general/forms/newsletter': dynamic(() => import('../../legacy/js/tastic/forms/newsletter/tastic')),
  'sm-general/hero-banner': dynamic(() => import('../../legacy/js/tastic/heroBanner/tastic')),
  'sm-general/organisation-schema': dynamic(() => import('../../legacy/js/tastic/organisationSchema/tastic')),
  'sm-general/product-details': dynamic(() => import('../../legacy/js/tastic/productDetail/tastic')),
  'sm-general/product-list': dynamic(() => import('../../legacy/js/tastic/productList/tastic')),
  'sm-general/spacer': dynamic(() => import('../../legacy/js/tastic/spacer/tastic')),
  'sm-general/sitelink-searchbox': dynamic(() => import('../../legacy/js/tastic/siteLinkSearch/tastic')),
  'sm-general/store-detail': dynamic(() => import('../../legacy/js/tastic/storeDetail/tastic')),
  'sm-general/store-detail-header': dynamic(() => import('../../legacy/js/tastic/storeDetailHeader/tastic')),
  'sm-general/storeFinder': dynamic(() => import('../../legacy/js/tastic/storeFinder/tastic')),
  'sm-general/sweepstakes-form': dynamic(() => import('../../legacy/js/tastic/sweepstakesForm/tastic')),
  'sm-general/team-gallery': dynamic(() => import('../../legacy/js/tastic/teamGallery/tastic')),
  'sm-general/text-image-box': dynamic(() => import('../../legacy/js/tastic/textImage/tastic')),
  'sm-general/tile': dynamic(() => import('../../legacy/js/tastic/tile/tastic')),
  'sm-general/video-teaser': dynamic(() => import('../../legacy/js/tastic/videoTeaser/tastic')),
  'sm-general/wishlists': dynamic(() => import('../../legacy/js/tastic/wishlist/tastic')),
  'sm-general/thank-you': dynamic(() => import('../../legacy/js/tastic/thankYou/tastic')),
  'sm-slider/event-teaser-slider': dynamic(() => import('../../legacy/js/tastic/sliders/event-teaser-slider/tastic')),
  'sport2k-footer': dynamic(() => import('../../legacy/js/tastic/sportFooter/tastic')),
  'sport2k-header': dynamic(() => import('../../legacy/js/tastic/sportHeader/tastic')),
  'sport2k-minimal-footer': dynamic(() => import('../../legacy/js/tastic/minimalfooter/tastic')),
  'sport2k-minimal-header': dynamic(() => import('../../legacy/js/tastic/minimalheader/tastic')),
  'sm-general/product-category-tiles': dynamic(() => import('../../legacy/js/tastic/productCategoryTiles/tastic')),
  'elements/markdown': dynamic(() => import('./ats-markdown')),
  default: dynamic(() => import('./not-found')),
};
