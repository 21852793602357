import UserToken from './UserToken'

const DEFAULT_UNIT_LOCALE = 'DE'


class TagManager {
    constructor(gtagFunc = 'gtag') {
        this.gtagFunc = gtagFunc
    }

    queryIdImpression = (objectIds, algoliaIndexName) => {
        window?.aa('convertedObjectIDsAfterSearch', {
            eventType: 'view',
            eventName: 'Search Hits Viewed',
            userToken: window.localStorage.getItem('userToken'),
            index: algoliaIndexName,
            queryID: window.localStorage.getItem('productQueryIds'),
            objectIDs: objectIds,
        })
    }

    addedToCartObjectIDsAfterSearch = (item, algoliaIndexName) => {
        if (!item) {
            return
        }

        window?.aa('addedToCartObjectIDsAfterSearch', {
            eventName: 'Added to Cart',
            userToken: window.localStorage.getItem('userToken'),
            index: algoliaIndexName,
            queryID: window.localStorage.getItem('productQueryIds'),
            objectIDs: [item.variant?.sku],
            objectData: [
                {
                    price: item.price / 100,
                    discount: item.discountedPrice && item.discountedPrice / 100,
                    quantity: 1,
                },
            ],
            value: item.totalPrice / 100,
            currency: 'EUR',
        })
    }

    purchasedObjectIDsAfterSearch = (items, sum, algoliaIndexName) => {
        const objectIDs = []
        const objectData = []

        items.forEach((item) => {
            objectIDs.push(item.variant?.sku)
            objectData.push({
                price: item.price / 100,
                discount: item.discountedPrice && item.discountedPrice / 100,
                quantity: item.count,
            })
        })

        window?.aa('purchasedObjectIDsAfterSearch', {
            eventName: 'Added to Cart',
            userToken: window.localStorage.getItem('userToken'),
            index: algoliaIndexName,
            queryID: window.localStorage.getItem('productQueryIds'),
            objectIDs,
            objectData,
            value: sum / 100,
            currency: 'EUR',
        })
    }

    hitViewdImpressions = () => {
        UserToken()
    }

    checkoutImpressions = () => {
        UserToken()
    }

    addPaymentInfo = (paymentInfo, productList) => {
        this.send('event', 'add_payment_info', {
            ...paymentInfo,
            items: productList,
        })
    }

    addShippingInfo = (shippingInfo, productList) => {
        this.send('event', 'add_shipping_info', {
            ...shippingInfo,
            items: productList,
        })
    }

    cartImpressions = (sum, productList) => {
        this.send('event', 'view_cart', {
            currency: 'EUR',
            value: sum / 100,
            items: productList,
        })
    }

    productImpressions = (productList, categoryName, categoryId) => {
        this.send('event', 'view_item_list', {
            item_list_id: categoryId,
            item_list_name: categoryName,
            items: productList,
        })
    }

    productClick = (product) => {
        this.send('event', 'select_item', {
            item_list_id: 'product_list',
            item_list_name: 'Product List',
            items: [product],
        })
    }

    productDetailImpressions = (product) => {
        this.send('event', 'view_item', {
            currency: 'EUR',
            value: product.price,
            items: [product],
        })
    }

    addProduct = (product) => {
        this.send('event', 'add_to_cart', {
            currency: 'EUR',
            value: product.price,
            items: [product],
        })
    }

    removeProduct = (product) => {
        this.send('event', 'remove_from_cart', {
            currency: 'EUR',
            value: product.price,
            items: [product],
        })
    }

    beginCheckout = (beginCheckoutInfo, productList) => {
        this.send('event', 'begin_checkout', {
            ...beginCheckoutInfo,
            items: productList,
        })
    }

    addWishList = (product) => {
        this.send('event', 'add_to_wishlist', {
            currency: 'EUR',
            value: product.price,
            items: [product],
        })
    }
    removeFromWishList = (product) => {
        this.send('event', 'remove_from_wishlist', {
            currency: 'EUR',
            value: product.price,
            items: [product],
        })
    }

    trackingToLogin = (accountInfo = {}) => {
        this.send('event', 'login', {
            ...accountInfo,
            businessUnit: accountInfo.accountInfo || DEFAULT_UNIT_LOCALE,
        })
    }

    trackingToLogout = (accountInfo = {}) => {
        this.send('event', 'logout', {
            ...accountInfo,
            businessUnit: accountInfo.businessUnit || DEFAULT_UNIT_LOCALE,
        })
    }

    trackingToRegistration = (registrationInfo = {}) => {
        this.send('event', 'registration', {
            ...registrationInfo,
            businessUnit: registrationInfo.businessUnit || DEFAULT_UNIT_LOCALE,
        })
    }

    purchase = (orderInformation, products) => {
        this.send('event', 'purchase', {
            ...orderInformation,
            items: products,
        })
    }

    order = (orderInfo) => {
        this.send('event', 'conversion', orderInfo)
    }

    send = (type = 'event', eventName, data) => {
        const gtag = this.getGtagFunc()
        if (!gtag) {
            return
        }

        gtag(type, eventName, data)
    }

    getGtagFunc = () => {
        if (!window || !window[this.gtagFunc]) {
            return null
        }

        return window[this.gtagFunc]
    }
}

export default new TagManager()
